import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Le 'making of' des chantiers`}</h1>
    <h3>{`Historique`}</h3>
    <p>{`Mon implication dans les chantiers de la ville de Toulouse à commencée lors du `}<a parentName="p" {...{
        "href": "/blog/chantiers"
      }}>{`Hackaton`}</a>{`. Elle s'est continuée naturellement dans le concours `}<a parentName="p" {...{
        "href": "http://data.toulouse-metropole.fr"
      }}>{`Défi Numérique`}</a>{`. L'équipe a produit une web-app et une app Android: `}<a parentName="p" {...{
        "href": "http://infotravaux.fr"
      }}>{`Info'Travaux`}</a>{` qui inclut les idées de visualisation décrites ici et y ajoute des fonctionnalités précieuses comme l'abonnement aux nouveaux chantiers dans les zones d'intérêt et surtout les itinéraires en voiture permettant d'éviter les chantiers. `}<em parentName="p">{`Le projet `}<a parentName="em" {...{
          "href": "http://infotravaux.fr"
        }}>{`Info'Travaux`}</a>{`  a gagné le prix du "Services aux citoyens".`}</em></p>
    <br />
    <center>
      <p><img parentName="p" {...{
          "src": "/gifs/chantiers.gif",
          "alt": null
        }}></img></p>
    </center>
    <br />
    <p>{`Google Maps et autres Waze peuvent parfois constater un ralentissement dans une rue, mais ni l'un ni l'autre ne sait pourquoi et pour combien de temps, `}<a parentName="p" {...{
        "href": "http://infotravaux.fr"
      }}>{`Info'Travaux`}</a>{` lui oui. `}</p>
    <h3>{`A la recherche de la visualisation efficace`}</h3>
    <p>{`Quand on essaye de placer sur une carte des centaines de punaises et parfois des dizaines sur 1 cm2, on se rend compte qu'il y a sûrement mieux à faire que de superposer des icônes/marqueurs identiques qui seront illisibles et très difficiles à sélectionner individuellement.
Il y a maintenant plus de 30 ans qu'`}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Edward_Tufte"
      }}>{`Edward Tufte`}</a>{` a proposé un critère objectif de la bonne utilisation de l'encre: le `}<strong parentName="p">{`data/ink ratio`}</strong>{`. En français et sur écran: le rapport `}<em parentName="p">{`nb données/nb de pixels`}</em>{`. Chaque trait, couleur, surface utilisé doit être questionnée à propos de son apport à la présentation des données en regard de la place qu'elle accapare au détriment des autres sur le papier, l'écran et ultimement sur la rétine et dans le cerveau de l'utilisateur. C'est un tamis très efficace pour apprendre à éliminer le bruit visuel et se concentrer sur l'essentiel.
Encore mieux, s'il est possible de pondérer chaque donnée par la `}<em parentName="p">{`valeur`}</em>{` qu'elle a pour l'utilisateur: `}<em parentName="p">{`(valeur X donnée) / pixels`}</em>{`, on peut créer des interfaces et des visualisations optimales pour les familles d'utilisateurs qui partagent les mêmes objectifs.
La mission que l'on assigne au marqueur est alors d'attirer l'attention du lecteur d'autant plus que le chantier est important pour lui. Un premier essai inspiré de `}<a parentName="p" {...{
        "href": "http://perceptible.fr/blog/chantiers"
      }}>{`visualisation`}</a>{` utilisait la durée restante. Un cercle de diamètre d'autant plus gros que le chantier allait encore longtemps perturber la circulation. Une fois réalisé et expérimenté plusieurs défauts se font jour et notamment deux: `}</p>
    <ol>
      <li parentName="ol">{`la taille des cercles devient très grande à cause de la forte dynamique des données (de 1j à 3 ans) jusqu'à devenir inutilisable `}</li>
      <li parentName="ol">{`le critère durée est trop écrasant, une gène énorme courte est totalement masquée par des gènes très légères ou nulles pour certain modes de transport pour peu qu'elles soient longues
Cette deuxième version va plus loin en faisant émerger des données le niveau de gène par mode de transport grâce à une pondération des différents impacts sur la circulation:`}</li>
    </ol>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "487px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.16564417177914%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACWklEQVQ4y32T63LaMBCFef93a2fSSYGEBgMBfJclWb7bnJ5dQtr0RzWzs7Ilfzq7R145E2CNRbTfIytLhBBQFAWMKWGdRds2nBs471DXNXzt9dlaWWtRVQaea/LcdR1WvgoIvkEcx9wc+LLnh3wXGjRNi74f9BCJpmkUImCJvudeWeM+WRuGAavK5hjnFssyYp5HjFP/kVsMIzeNLecdbrfbl1gYQ99hmQZGh3kcME0TVqWNcMmekJQblPaI0h1g/ElzXKyRmR18k0LGAyZjmhcU1yOadIcu3cImEbzzWFl/Rl7tFJpXvyAHFHaPpmOf6ivnkSr+dzyAIXkl8BU23sNZAo07Uckz3pNvSMsXhUpUPCgpNpy/qVp5lvW29wocpxnF5YCawFaBEYGOPfTvBP78C7hTVaIuIzgzr6q6bgsUhIfWfAJzAkO8QVdwf/z2B5gIMP2uwMy8EPhG4IUHbVSl4Z5+qAnL4UKsJmnJ8Qn1lcDyAHvewkkP76Wt2cMfqqwfgjo8zQMd5jUavGYBdr0ltGBPB8zL7d5DMSV79JAKSxdR2VZLTOl0UopC6dkJlmpcfda59FUquOYbdfqrKdt7yaJQyksIFHOk3NTIR896iG8y9i5jTu5XiRXkVfQJlB7WMY1K1qguO9jKicsHPVnKlTsnrqY0ou0q/G/cFZ6ocEeXX+DS4/0eSimiSHJSrnFmL6ePe7csM2PSmD+y9PZ2m9nXEfmZ7l+eFVjRFFMarMQ5KSW0pZb2uIO1lpurUh8SNUOcl7/JMXd9A28KhDyiy1SX8s+6JvgNOGYnmqaSQcIAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "NiveauxGene",
          "title": "NiveauxGene",
          "src": "/static/3816fb35aa41844b1be8386e6e1c013b/7b439/NiveauxGene.png",
          "srcSet": ["/static/3816fb35aa41844b1be8386e6e1c013b/222b7/NiveauxGene.png 163w", "/static/3816fb35aa41844b1be8386e6e1c013b/ff46a/NiveauxGene.png 325w", "/static/3816fb35aa41844b1be8386e6e1c013b/7b439/NiveauxGene.png 487w"],
          "sizes": "(max-width: 487px) 100vw, 487px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`On peut combiner les deux dimensions :`}</p>
    <ul>
      <li parentName="ul">{`le niveau de gène sur une échelle de 0 à 5`}</li>
      <li parentName="ul">{`la durée restante mais bornée à 3 mois pour éviter le défaut de la forte dynamique`}</li>
    </ul>
    <p>{`en un seul indicateur, selon les principes suivants:`}</p>
    <ul>
      <li parentName="ul">{`la taille du disque est d'autant plus grande que le chantier est gênant, avec une légende de couleur en redondance`}</li>
      <li parentName="ul">{`l'épaisseur de la couronne représente la durée restante. Disque plein si encore plus de 3 mois et un simple cercle pour la semaine. `}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "252px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.576687116564415%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAACfElEQVQ4y31TW27TQBQNHyyEVbAHProHlgASi4BPPvhhAcAXtAkIUFtVrSqSpqFN0tC4cWzXcRI7bvyK7diOZw53Blo1FBjp6s54Zo7PuedOxXFsS1VV3bIsYzKZGmEUGXEc34kr1zVM0zQM3ZB5Op0Y4/HYsKyRMbVtPU1Ty7btnYo3d1H/1kD79BQXgyH+NUzzEt2zDjp07rh5jKGqonfWRbvdhjrU5ZlFFKkVMSl5XlJinDNGTNh87rEwDJnv+yzPMyb2iAHb2d5muqbJtTjMKcqyFFncB905l4C2Z7I0WyBbLtHtdIipCk3ToSgXiOOF/Luh63j29AnevX0j185shtFoBKV/jsnUZr8B+xKwVn/NTtRdeXBJoFQzlIytSWa01rQhgiC4Uw7BdA3wa3uTnVknYAWHZY3RP+9j7vs3QNd5MBjAdd2/lXgdEO0XDPp7EB4xTLFaraT8LM9vbojiV6tb+FirIUkSUE3heR6skUVA0Tpg/GWDrbrPMZsvsLe3i3q9gaNGg1ztkcRQshP1qlWrONjfR1EUEjQIAwno+8E6YKE0Gbd1xPkK6uCCLpuYOQ50dYBe7wcCP5QsyXXJ/v+SG5twH91j/uMHyC5/IKEOKrJMmpNRFiGGKEEURVgsFqBWkQyFecKkgBhS94j9fqX89ArOwwrzNu7zWf0zb7TavNP6zpvNFj88OOTNo2O+olYLg4CnScqpbjzLltyxbW4al5wM5IqisKuruQD81YfZ1kuw+gcUJblsjjAdj+lljKDphnT9egh2xS2j/hwEqFTSZbZMSH7CEZGEiKRSZBFJjfI8p3kqv5NEuUfuyjWB346Q9pf0lls/AcKfx6RlDaxAAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "legend",
          "title": "legend",
          "src": "/static/f52bafd2fe5cf7818e49a4352a83034d/5e02b/legend.png",
          "srcSet": ["/static/f52bafd2fe5cf7818e49a4352a83034d/222b7/legend.png 163w", "/static/f52bafd2fe5cf7818e49a4352a83034d/5e02b/legend.png 252w"],
          "sizes": "(max-width: 252px) 100vw, 252px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Ainsi combinés, le nombre de pixels occupés sur l'écran est d'autant plus grand que le chantier mérite l'attention du lecteur. Quelques bonnes propriétés de cet indicateur:`}</p>
    <ul>
      <li parentName="ul">{`les chantiers proches se superposent pour former une grande tâche de couleur qui indique tout naturellement et sans plus de détails un quartier fortement perturbé et pour un temps certain`}</li>
      <li parentName="ul">{`en zoom large les grosses tâches masquent les petites mais se séparent quand l'agrandissement est suffisant`}</li>
      <li parentName="ul">{`le caractère quasi aléatoire de la position, taille et épaisseur fait que les marqueurs se distinguent et se sélectionnent facilement`}</li>
    </ul>
    <h3>{`Mode d'emploi`}</h3>
    <p>{`Chaque marqueur est un chantier. `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Détails`}</strong>{`: `}<strong parentName="p">{`Clic`}</strong>{` permet de faire apparaître une bulle d'information à propos du chantier. `}<strong parentName="p">{`Maj clic`}</strong>{` permet d'en ouvrir plusieurs à la suite.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Navigation spatiale`}</strong>{`: le curseur en forme de main permet de faire glisser la carte par `}<strong parentName="p">{`appuyer-glisser`}</strong>{`. `}<strong parentName="p">{`Clic-clic`}</strong>{` fait un zoom avant, `}<strong parentName="p">{`maj clic-clic`}</strong>{` un zoom arrière. Tracer un rectangle avec `}<strong parentName="p">{`maj-appuyer-déplacer-relâcher`}</strong>{` zoom sur cette zone. Et bien sûr les icônes `}<strong parentName="p">{`+`}</strong>{` et `}<strong parentName="p">{`-`}</strong>{` en haut à gauche permettent le zoom avant/arrière`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Filtre`}</strong>{`: les icônes voiture, piéton, vélo permettent de ne considérer que les chantiers qui impactent un mode de transport en particulier. Le i rassemble tous les chantiers y compris ceux sans influence sur la circulation`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Voyage dans le temps`}</strong>{`: la glissière en bas de la carte permet de faire apparaître la situation à une date antérieure ou de se projeter dans le futur pour voir ce que seront les chantiers dans 3 mois. `}</p>
 <center>
          <p parentName="li">{` `}<a parentName="p" {...{
              "href": "https://alainro.github.io/sandbox/chantiersTlse/carte.html"
            }}>{`Ouvrir dans une fenêtre séparée`}</a></p>
          <p parentName="li">{` `}<strong parentName="p">{`A lire dans Chrome, Firefox ou Safari`}</strong>{` `}</p>
 <iframe frameBorder="0" allowFullScreen height="700" width="690" src="/sandbox/chantiersTlse/carte.html" scrolling="no">
          </iframe>
        </center>
      </li>
    </ul>
    <h3>{`Les Données Ouvertes`}</h3>
    <p>{`C'est à l'initiative de la Communauté Urbaine de Toulouse Métropole que les données concernant les chantiers sont rassemblées à l'intérieur d'une application nommée Orion. Chaque jour une partie des données gérées sont exportées vers la zone `}<a parentName="p" {...{
        "href": "http://data.toulouse-metropole.fr/"
      }}>{`Open Data`}</a>{`  où elles deviennent accessibles à tout un chacun qui peut la télécharger en divers formats.`}</p>
    <h4>{`Améliorer les données et leur mise à jour`}</h4>
    <p>{`La mise à disposition des données est un bénéfice incontestable. Et on en veut immédiatement plus. Au jour d'écriture de ce billet (oct 2014), on aimerait bien `}<strong parentName="p">{`quelques suppléments`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Un prévisionnel serait précieux`}</strong>{`: les chantiers sont publiés le jour de leur démarrage prévu. Dommage pour les chantiers qui durent une journée! Disposer d'un prévisionnel pour les chantiers, même s'il est amené à changer, pourrait être très précieux. Est ce possible ?`}</li>
      <li parentName="ul"><strong parentName="li">{`Quelques anomalies`}</strong>{`: certains jours le fichier n'est pas disponible au téléchargement . Ou identique à celui de la veille. Ou est un fichier valide mais les données qu'il contient sont incomplètes (des chantiers publiés la veille ne sont plus là mais sont à nouveau présent dans le fichier du lendemain). L'exploitation des données ouvertes doit trouver des solutions pour tolérer ces aléas.`}</li>
      <li parentName="ul"><strong parentName="li">{`Pas de mise à jour en cours de route`}</strong>{`: certainement que des modifications ont lieu après la publication des données (chantier annulé ou terminé avant la date prévue, ...). On ne constate pourtant aucune mise à jour dans l'open data. Les paramètres d'un chantier publié restent identiques d'un jour à l'autre jusqu'à ce que le chantier disparaisse de la liste. Pourquoi ?`}</li>
      <li parentName="ul"><strong parentName="li">{`Pourraient être plus précises`}</strong>{`: l'impact du chantier est pour le moment très grossier: un point GPS, un nom de rue et parfois une fourchette de numéros dans la rue. S'il était possible de récupérer les plans d'emprise alors les trajets d'évitement serait plus précis y compris pour le piéton et la personne à mobilité réduite. `}</li>
    </ul>
    <h4>{`Les données cachées dans les données`}</h4>
    <p>{`Comme très souvent, les données sont instructives pas seulement par leur valeur et leur évolution mais aussi par leur présence (les dates de mises à jour) ou à leur disparition soudaine. Et les chantiers n'échappent pas à la règle. `}</p>
    <p>{`Certains sont très sages: le chantier est répété à l'identique tous les jours entre les dates de début/fin prévues initialement.`}</p>
    <p>{`Tandis que d'autres:`}</p>
    <ul>
      <li parentName="ul">{`apparaissent plus tard que la date de début déclarée`}</li>
      <li parentName="ul">{`disparaissent plus tôt que la date de fin définie`}</li>
    </ul>
    <p>{`Probablement que les données exportées représentent en fait le `}<strong parentName="p">{`permis de mener un chantier`}</strong>{` qui encadre le déroulement pour éviter de mélanger dans une même tranchée et au même moment des interventions sur l'eau, le gaz et l'électricité... Il serait alors logique qu'aucune mise à jour rétroactive ne soit effectuée. Mais le citoyen préfèrerait certainement des informations plus proches de la réalité de la rue. Sont elles disponibles ?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      